import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { JsonData } from "../components/jsonData";
import { getBlogToken } from "../utils/customApiCall";

import Paper from '@mui/material/Paper';

import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { Button } from "@mui/material";

const storageAccountName = process.env.staticStorageAccount;
const anonymousCredential = new AnonymousCredential();

let blobServiceClient; 

async function uploadFilesToAzure () {
  let sasToken = window.localStorage.getItem("blogSaSToken");

  blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
  
  const containerClient = blobServiceClient.getContainerClient("images");

  const browserFiles = document.getElementById("fileInput").files;

  for (let i = 0; i < browserFiles.length; i++) {
    let browserFile = browserFiles[i];

    let blockBlobClient = containerClient.getBlockBlobClient(browserFile.name);

    await blockBlobClient.uploadData(browserFile, {
      rangeSize: 4 * 1024 * 1024, // 4MB range size
      concurrency: 20, // 20 concurrency
      onProgress: ev => console.log(ev)
    });
  }
} 

async function addFileToAzure (fileName) {
  let sasToken = window.localStorage.getItem("blogSaSToken");

  blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
  
  const containerClient = blobServiceClient.getContainerClient("blogs");

  const blockBlobClient = containerClient.getBlockBlobClient(fileName);

  let content = "Example blob content";

  const uploadBlobResponse = await blockBlobClient.upload(content, content.length);
  console.log(`Upload block blob ${fileName} successfully`, uploadBlobResponse);
} 

async function getContainers (sasToken) {
  blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
  
  let containers = blobServiceClient.listContainers();

  let contList = [];
  let i = 1;
  for await (const container of containers) {
    contList.push(container.name);
    console.log(`Container ${i++}: ${container.name}`);
  }

  return contList;
}

const StorageContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
            getBlogToken(response.accessToken).then((sasToken) => {
              window.localStorage.setItem("blogSaSToken", sasToken);
              blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
              getContainers(sasToken).then(value => {
                setGraphData(value)
              })
            });
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
          { graphData ? <div>
            <JsonData graphData={graphData} />
            <p>
              <input id="fileName" type="text" size="10" placeholder="File name" onKeyDown={(e) => {
                  if (e.code !== "Enter") return;
                  addFileToAzure(e.currentTarget.value);
                  e.currentTarget.value = '';
              }} />
              <input type="file" id="fileInput" multiple />
              <Button onClick={uploadFilesToAzure}>Upload</Button>
            </p>
          </div> : null }
      </Paper>
  );
};

const StoragePage = () => (
  <Layout>
    <Seo title="Storage" />
    <h1>Storage</h1>
    <StorageContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default StoragePage
